import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Grid } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./Trades.css";
import {
  getBots,
} from "../../services/exchangeService";
import { jwtDecode } from "jwt-decode";
import { Select, Option } from "@material-tailwind/react";
import NotificationComponent from "../NotificationComponent";

const TradesComponent = ({ trades }) => {
  let [selectedBot, setSelectedBot] = useState("bybit_futures");
  const [availableBots, setAvailableBots] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const initialized = useRef(false);
  const gridRef = useRef(null);

  const [tableInitialized, setTableInitialized] = useState(false);
  const [globalParams, setGlobalParams] = useState(null);

  const [showNotification, setShowNotification] = useState(false);

  let callInterval;

  const handleBotChange = (e) => {
    const curBot = e;
    let url = new URL(window.location.href);
    let params = url.searchParams;
    params.set("bot_name", curBot);
    window.history.replaceState({}, "", url);
    setSelectedBot(e);
  };

  const populateBots = async () => {
    const { data: bots } = await getBots();
    setAvailableBots(bots);
  };

  const definedGlobalColumns = [
    "entry_datetime",
    "symbol",
    "net_pnl",
    "status",
    "side",
    "leverage",
    "entry_price",
    "tp_percent",
    "tp_price",
    "sl_percent",
    "sl_price",
    "exit_type",
    "duration",
    "exit_price",
    "exit_datetime",
    "projected_profit_price_change",
    "projected_loss_price",
    "projected_loss_price_change",
    "projected_gross_profit",
    "projected_gross_loss",
    "projected_net_profit",
    "projected_net_loss",
    "projected_rr_gross",
    "projected_rr_net",
    "_id",
    "exchange",
  ];

  function exportVisibleColumnsToCSV() {
    // Get the visible columns
    const visibleColumnKeys = globalParams.api
      .getAllDisplayedColumns()
      .map((col) => col.getColId());

    // Export the data
    globalParams.api.exportDataAsCsv({
      columnKeys: visibleColumnKeys, // Export only visible columns
    });
  }

  function exportDefinedColumnsToCSV() {
    // Get the visible columns
    const visibleColumnKeys =  [...definedGlobalColumns];

    // Export the data
    globalParams.api.exportDataAsCsv({
      columnKeys: visibleColumnKeys, // Export only visible columns
    });
  }

  const fetchData = (params) => {
    if (trades) {
      setTimeout(() => {
        const columnDefs = definedGlobalColumns.map((field) => {
          return { field };
        });
        const symbolColumn = columnDefs.find((k) => k.field === "symbol");
        if (symbolColumn) {
          symbolColumn.cellRenderer = function (params) {
            // Mount React component dynamically
            const eDiv = document.createElement("div");
            ReactDOM.render(<TVCellRenderer {...params} />, eDiv);
            return eDiv;
          };
          symbolColumn.width = 50;
        }
        if (trades && trades[0] && params) {
          if (!tableInitialized) {
            trades.forEach((element) => {
              Object.keys(element).forEach((key) => {
                if (!columnDefs.find((k) => k.field === key)) {
                  columnDefs.push({
                    field: key,
                  });
                }
              });
            });
            columnDefs.forEach((element) => {
              element.headerName = capitalizeEachWord(
                element.field.split("_").join(" ")
              );
            });
            params.api.setGridOption("columnDefs", columnDefs); // Set the data using params.api
          }
          setGlobalParams(params);
          params.api.setGridOption("rowData", trades);
          params.api.autoSizeAllColumns();
          setTableInitialized(true);
        }
      });
    } else {
      let url = new URL(window.location.href);
      let urlParams = url.searchParams;

      const reqParams = new URLSearchParams({
        symbol: urlParams.get("symbol"),
        from_timestamp: urlParams.get("from_timestamp"),
        to_timestamp: urlParams.get("to_timestamp"),
        bot_name: urlParams.get("bot_name") || "bybit_futures",
      });

      const botName = urlParams.get("bot_name") || "bybit_futures";

      if (reqParams.get("symbol") === "null") {
        reqParams.delete("symbol");
      }
      if (reqParams.get("from_timestamp") === "null") {
        reqParams.delete("from_timestamp");
      }
      if (reqParams.get("to_timestamp") === "null") {
        reqParams.delete("to_timestamp");
      }

      fetch(
        "https://j444xz2iu5oaxlrni7o264asgq0kbvks.lambda-url.us-east-1.on.aws/trades?" +
          reqParams.toString(),
        {
          headers: {
            Authorization: "LkAFZemOSK2OU8",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          let columnDefs = botName === 'alert_bot' ? 
          ["entry_datetime",
          "symbol",
          "side",
          "entry_price",
          "tp_percent",
          "tp_price",
          "sl_percent",
          "sl_price",
          "exit_type"] : definedGlobalColumns;
          columnDefs = columnDefs.map((field) => {
            if (field === 'tp_price' || field === 'sl_price' || field === 'entry_price' ) {
              return { field , onCellClicked: (e)  => {
                copyValue(e.value);
              }};
            }
            return { field};
          });
          const symbolColumn = columnDefs.find((k) => k.field === "symbol");
          if (symbolColumn) {
            symbolColumn.cellRenderer = function (params) {
              // Mount React component dynamically
              const eDiv = document.createElement("div");
              ReactDOM.render(<TVCellRenderer {...params} />, eDiv);
              return eDiv;
            };
            symbolColumn.width = 50;
          }
          if (res && res[0]) {
            if (!tableInitialized) {
              if (botName !== 'alert_bot') {
                res.forEach((element) => {
                  Object.keys(element).forEach((key) => {
                    if (!columnDefs.find((k) => k.field === key)) {
                      columnDefs.push({
                        field: key,
                      });
                    }
                  });
                });
              }
            }
            columnDefs.forEach((element) => {
              element.headerName = capitalizeEachWord(
                element.field.split("_").join(" ")
              );
            });
            params.api.setGridOption("columnDefs", columnDefs); // Set the data using params.api
            setGlobalParams(params);
            params.api.setGridOption("rowData", res);
            params.api.autoSizeAllColumns();
            setTableInitialized(true);
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  };


  const copyValue = (e) => {
    navigator.clipboard
      .writeText(e)
      .then(() => {
        handleShowNotification();
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
      });
  };

  const handleShowNotification = () => {
    setShowNotification(true);
  };

  const handleHideNotification = () => {
    setShowNotification(false);
  };

  // Function to set row data once the grid is ready
  const onGridReady = (params) => {
    if (callInterval) {
      clearInterval(callInterval);
    }
    fetchData(params);
    if (!trades) {
      callInterval = setInterval(() => {
        fetchData(params);
      }, 60000);
    }
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const gridOptions = {
        columnDefs: [],
        defaultColDef: {
          resizable: true,
          filter: true,
          sortable: true,
        },
        pagination: true,
        paginationPageSize: 50,
        animateRows: true,
        onGridReady: onGridReady, // Add the onGridReady event
      };

      new Grid(gridRef.current, gridOptions);
    }

    let url = new URL(window.location.href);
    let urlParams = url.searchParams;

    setSelectedBot(urlParams.get("bot_name") || "bybit_futures");

    let jwt = localStorage.getItem("access_token");
    if (jwt) {
      jwt = jwtDecode(jwt);
      if (jwt && jwt["cognito:groups"]) {
        if (jwt["cognito:groups"].indexOf("super-admin") > -1) {
          setIsSuperAdmin(true);
          populateBots();
        } else {
          setIsSuperAdmin(false);
        }
      }
    }
    return () => {
      clearInterval(callInterval);
    };
  }, []);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const gridOptions = {
        columnDefs: [],
        defaultColDef: {
          resizable: true,
          filter: true,
          sortable: true,
        },
        pagination: true,
        paginationPageSize: 50,
        animateRows: true,
        onGridReady: onGridReady, // Add the onGridReady event
      };

      new Grid(gridRef.current, gridOptions);
    } else if (globalParams) {
      fetchData(globalParams);
    }
  }, [trades]);

  useEffect(() => {
    if (globalParams) {
      globalParams.api.setGridOption("rowData", []);
      fetchData(globalParams);
    }
  }, [selectedBot]);

  function openTV(symbol) {
    window.open(
      `https://www.tradingview.com/chart/?symbol=BINANCE%3A${symbol}.P`
    );
  }

  function TVCellRenderer(params) {
    return (
      <button onClick={(e) => openTV(params.data.symbol)}>
        {params.value}
      </button>
    );
  }

  return (
    <>
      {availableBots?.length && isSuperAdmin && (
        <div className="mb-1 flex flex-col items-end gap-4 md:flex-row">
          <div className="w-full md:mb-1">
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="py-1"
            >
              <label style={{ color: "#fff", marginLeft: "10px" }}>
                Select Bot
              </label>
            </div>
            <Select
              onChange={handleBotChange}
              value={selectedBot}
              label="Select Bot"
              className="w-full text-white"
              color="red"
            >
              {availableBots.map((c) => {
                return (
                  <Option key={c} value={c}>
                    {c}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
      )}
      <div className="trades-grid">
        <button onClick={exportVisibleColumnsToCSV} id="exportVisButton">
          Export Visible Columns Data
        </button>
        <button onClick={exportDefinedColumnsToCSV} id="exportButton">
          Export Defined Columns
        </button>
        <br />
        <div
          id="myGrid"
          ref={gridRef}
          className="ag-theme-alpine-dark grid-trades-container"
          style={{ height: "calc(100vh - 160px)", width: "100%" }}
        ></div>
      </div>
      <NotificationComponent
        show={showNotification}
        message="Value copied to clipboard!"
        onHide={handleHideNotification}
      />
    </>
  );
};

function capitalizeEachWord(str) {
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

export default TradesComponent;
