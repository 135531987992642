import http from './httpService'

const exchangeConfigUrl = `${process.env.REACT_APP_API_URL}/exchange`
const botsConfigUrl = `${process.env.REACT_APP_API_URL}/bots`

export function getExchangeConfig(params) {
    return http.get(exchangeConfigUrl, {params})
}

export function updateExchangeConfig(config, params) {
    return http.put(exchangeConfigUrl, config, {params})
}

export function getBots() {
    return http.get(botsConfigUrl)
}
